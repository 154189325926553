import React, { Component, useState } from 'react'
import { Link } from "react-router-dom";
import { Breadcrumb, Divider, Layout, Menu, MenuProps, theme, Col, Row, Button, Dropdown, Space, Typography } from 'antd';
import { LocalStore } from '../utils/LocalStore/index';
import { DownOutlined } from '@ant-design/icons';
// import { useTranslation } from 'react-i18next';
// import { withTranslation } from 'react-i18next';
import { Trans, withTranslation } from 'react-i18next';
import i18n from '../utils/locales/i18n';
const { Header, Content, Footer } = Layout;

class MyHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: 'vi',
        };
    }

    jumpToReleventDiv = (id) => {
        const releventDiv = document.getElementById(id);
        // behavior: "smooth" parameter for smooth movement
        releventDiv.scrollIntoView({ behavior: "smooth" });
    }

    componentDidMount() {
        let lg = LocalStore.getInstance().read('language') || "vi";
        this.setLanguage(lg);
    }

    setLanguage(language) {
        console.log(`Setting language ${language}`);
        this.setState({ language: language });
        LocalStore.getInstance().save('language', language);
        i18n.changeLanguage(language);
    }

    render() {
        // const { t } = useTranslation()
        // const { t } = this.props

        let items = [];
        return (
            <Header className='nota-header'>
                <Row align="middle">
                    <Col span={6} align="left" style={{ padding: '5px 0' }}>
                        <div className="logo">
                            <img className="img-fluid nota-header-img" src="logo192.png" alt="" />
                        </div>
                    </Col>
                    <Col span={18} align="right" className='nota-header-menu'>
                        <Button onClick={() => this.jumpToReleventDiv('introduce')} className='nota-btn-header'><Trans i18nKey='header.introduce' /></Button>
                        <Button onClick={() => this.jumpToReleventDiv('product')} className='nota-btn-header'><Trans i18nKey='header.product' /></Button>
                        <Button onClick={() => this.jumpToReleventDiv('partner')} className='nota-btn-header'><Trans i18nKey='header.parnner' /></Button>
                        <Button onClick={() => this.jumpToReleventDiv('carierr')} className='nota-btn-header'><Trans i18nKey='header.recruit' /></Button>
                        <Button onClick={() => this.jumpToReleventDiv('footer')} className='nota-btn-header'><Trans i18nKey='header.contact' /></Button>
                        <Dropdown
                            menu={{
                                items,
                                selectable: true,
                                defaultSelectedKeys: [this.state.language],
                            }}
                            dropdownRender={(menu) => (
                                <div style={{ border: '1px solid black', backgroundColor: 'white' }}>
                                    <Button type="none" onClick={() => this.setLanguage('vi')}>
                                        {/* <img src='images/vi-flag.png' width='20px' height='' alt='' /> */}
                                        Tiếng Việt
                                    </Button>
                                    <br></br>
                                    <Button type="none" onClick={() => this.setLanguage('en')}>
                                        {/* <img src='images/en-flag.png' width='20px' height='' alt='' /> */}
                                        English
                                    </Button>
                                </div>
                            )}
                        >
                            <Typography.Link>
                                {this.state.language == 'vi' &&
                                    <Space style={{ paddingLeft: 16, fontSize: '14px', fontWeight: 'bold' }}>
                                        <img src='images/vi-flag.png' width='20px' height='' alt='' />VI
                                    </Space>}
                                {this.state.language == 'en' &&
                                    <Space style={{ paddingLeft: 16, fontSize: '14px', fontWeight: 'bold' }}>
                                        <img src='images/en-flag.png' width='20px' height='' alt='' />EN
                                    </Space>
                                }
                            </Typography.Link>
                        </Dropdown>
                    </Col>
                </Row>
            </Header >
        )
    }
}

export default withTranslation()(MyHeader);
