import Layout from "./Layout";
import { Row, Col, Image, Button } from 'antd'
import { Trans, withTranslation } from 'react-i18next';

const Home = () => {
  return (
    <div style={{ margin: '20px, 0', backgroundColor: '#fff', color: 'black', fontSize: '16px' }}>
      <Row style={{ margin: '70px 0 0 0' }}>
        <img src='images/image77.jpg' width='100%' height='' alt='' />
      </Row>

      <Row className='nota-introduce' id='introduce' align='middle' style={{ textAlign: 'center'}}>
        <Col span={24} align='middle'>
          <Row>
            <Col span={24} align='middle' style={{ fontWeight: 'bold', fontSize: '20px' }}>
              <p><Trans i18nKey='home.introduce' /></p>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12} align='middle' style={{ margin: '20px, 0', width: '100%' }}>
              <div className='nota-introduce-ele' >
                <img src='images/introduce-1.png' />
                <p className='nota-introduce-title'><Trans i18nKey='home.established' /></p>
                <p>2020</p>
              </div>
            </Col>

            <Col md={6} sm={12} align='middle' style={{ margin: '20px, 0', width: '100%' }}>
              <div className='nota-introduce-ele' >
                <img src='images/introduce-2.png' />
                <p className='nota-introduce-title'><Trans i18nKey='home.mission' /></p>
                <p><Trans i18nKey='home.mission-detail' /></p>
              </div>
            </Col>
            <Col md={6} sm={12} align='middle' style={{ margin: '20px, 0', width: '100%' }}>
              <div className='nota-introduce-ele' >
                <img src='images/introduce-3.png' />
                <p className='nota-introduce-title'><Trans i18nKey='home.company-size' /></p>
                <p>50+</p>
              </div>
            </Col>
            <Col md={6} sm={12} align='middle' style={{ margin: '20px, 0', width: '100%' }}>
              <div className='nota-introduce-ele' >
                <img src='images/introduce-4.png' />
                <p className='nota-introduce-title'><Trans i18nKey='home.vision' /></p>
                <p><Trans i18nKey='home.vision-detail' /></p>
              </div>
            </Col>

          </Row>
        </Col>
      </Row>

      <Row id='product'>
        <Col span={24} align='middle'>
          <Row>
            <Col span={24} align='middle' style={{ fontWeight: 'bold', fontSize: '20px' }}>
              <p><Trans i18nKey='home.services' /></p>
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={24} align='middle' className='nota-product-ele'>
              <img src='images/product-1.png' className='nota-product-img' />
              <p className='nota-product-head'><Trans i18nKey='home.publishing' /></p>
              <p><Trans i18nKey='home.publishing-detail' /></p>
            </Col>
            <Col md={12} sm={24} align='middle' className='nota-product-ele'>
              <img src='images/product-2.png' className='nota-product-img' />
              <p className='nota-product-head'><Trans i18nKey='home.development' /></p>
              <p><Trans i18nKey='home.development-detail' /></p>
            </Col>
            <Col md={12} sm={24} align='middle' className='nota-product-ele'>
              <img src='images/product-3.png' className='nota-product-img' />
              <p className='nota-product-head'><Trans i18nKey='home.software' /></p>
              <p><Trans i18nKey='home.software-detail' /></p>
            </Col>
            <Col md={12} sm={24} align='middle' className='nota-product-ele'>
              <img src='images/product-4.png' className='nota-product-img' />
              <p className='nota-product-head'><Trans i18nKey='home.esport' /></p>
              <p><Trans i18nKey='home.esport-detail' /></p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row id='partner'>
        <Col span={24} align='middle'>
          <Row>
            <Col span={24} align='middle' style={{ fontWeight: 'bold', fontSize: '20px' }}>
              <p><Trans i18nKey='home.parnner' /></p>
            </Col>
          </Row>
          <Row align='middle'>
            <Col span={6} align='middle' className='nota-partner-ele'>
              <img src='images/partner-1.png' className='nota-partner-img' />
            </Col>
            <Col span={6} align='middle' className='nota-partner-ele'>
              <img src='images/partner-2.png' className='nota-partner-img' />
            </Col>
            <Col span={6} align='middle' className='nota-partner-ele'>
              <img src='images/partner-3.png' className='nota-partner-img' />
            </Col>
            <Col span={6} align='middle' className='nota-partner-ele'>
              <img src='images/partner-4.png' className='nota-partner-img' />
            </Col>
            <Col span={6} align='middle' className='nota-partner-ele'>
              <img src='images/partner-5.png' className='nota-partner-img' />
            </Col>
            <Col span={6} align='middle' className='nota-partner-ele'>
              <img src='images/partner-6.png' className='nota-partner-img' />
            </Col>
            <Col span={6} align='middle' className='nota-partner-ele'>
              <img src='images/partner-7.png' className='nota-partner-img' />
            </Col>
            <Col span={6} align='middle' className='nota-partner-ele'>
              <img src='images/partner-8.png' className='nota-partner-img' />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className='nota-carierr' id='carierr' align='middle' style={{ textAlign: 'center', margin: '20px', padding: '20px' }}>
        <Col span={24} align='middle'>
          <Row>
            <Col span={24} align='middle' style={{ fontWeight: 'bold', fontSize: '20px' }}>
              <p><Trans i18nKey='home.career' /></p>
            </Col>
          </Row>
          <Row>
            <Col md={8} sm={12} align='left' style={{ padding: '20px', width: '100%' }}>
              <div className='nota-carierr-ele' >
                <p className='nota-carierr-title'>3D Game Artist Intern</p>
                <p>Full time</p>
              </div>
            </Col>

            <Col md={8} sm={12} align='left' style={{ padding: '20px', width: '100%' }}>
              <div className='nota-carierr-ele' >
                <p className='nota-carierr-title'>Marketing Intern</p>
                <p>Full time</p>
              </div>
            </Col>
            <Col md={8} sm={12} align='left' style={{ padding: '20px', width: '100%' }}>
              <div className='nota-carierr-ele' >
                <p className='nota-carierr-title'>Backend Leader</p>
                <p>Full time</p>
              </div>
            </Col>
            <Col md={8} sm={12} align='left' style={{ padding: '20px', width: '100%' }}>
              <div className='nota-carierr-ele' >
                <p className='nota-carierr-title'>2D Game Artist Intern</p>
                <p>Full time</p>
              </div>
            </Col>
            <Col md={8} sm={12} align='left' style={{ padding: '20px', width: '100%' }}>
              <div className='nota-carierr-ele' >
                <p className='nota-carierr-title'>2D Artist Intern</p>
                <p>Full time</p>
              </div>
            </Col>
            <Col md={8} sm={12} align='left' style={{ padding: '20px', width: '100%' }}>
              <div className='nota-carierr-ele' >
                <p className='nota-carierr-title'>Game Publishing Manager (Remote)</p>
                <p>Full time</p>
              </div>
            </Col>
            <Col md={8} sm={12} align='left' style={{ padding: '20px', width: '100%' }}>
              <div className='nota-carierr-ele' >
                <p className='nota-carierr-title'>2D Game Artist</p>
                <p>Full time</p>
              </div>
            </Col>

          </Row>
        </Col>
      </Row>
    </div>
  )
};

export default withTranslation() (Home);
